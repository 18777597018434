<template>
    <div class="add-cohort-container">
        <CohortsHeaderComponent/>
        <div class="add-cohort-container__wrapper grid">
           <AddCohortFormComponent/>
           <CohortOverviewComponent/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import AddCohortFormComponent from '@/modules/cohort-post/components/AddCohortFormComponent.vue';
import CohortOverviewComponent from '@/modules/cohort-post/components/CohortOverviewComponent.vue';
import CohortsHeaderComponent from '@/modules/dashboard/components/cohort-components/CohortsHeaderComponent.vue';

export default defineComponent({
  components: {
    AddCohortFormComponent,
    CohortsHeaderComponent,
    CohortOverviewComponent
  }
});
</script>
<style lang="scss" scoped>
.add-cohort-container {
    margin: 0 20px 0 10px;
    max-width: 1230px;

    &__wrapper {
      grid-template-columns: 2fr 1fr;
      padding: .9rem .5rem .5rem .5rem;
      gap: 1.6rem;
    }
}
</style>
