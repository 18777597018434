<template>
    <div class="add-cohort-overview flex-column jc-sb">
        <div class="add-cohort-overview__header-wrapper">
           <p class="add-cohort-overview__header default-text">Overview: <span>Cohort 1</span></p>
        </div>
        <div class="add-cohort-overview__overview flex-column">
           <div class="add-cohort-overview__overview-item flex-column">
              <p class="add-cohort-overview__overview-title default-text">Course</p>
              <p class="add-cohort-overview__overview-data default-text">Air Source Heat Pump Level 3</p>
           </div>
           <div class="add-cohort-overview__overview-item flex-column">
              <p class="add-cohort-overview__overview-title default-text">Start date</p>
              <p class="add-cohort-overview__overview-data default-text">15/11/2022</p>
           </div>
           <div class="add-cohort-overview__overview-item flex-column">
              <p class="add-cohort-overview__overview-title default-text">Start time</p>
              <p class="add-cohort-overview__overview-data default-text">09:00 AM</p>
           </div>
           <div class="add-cohort-overview__overview-item flex-column">
              <p class="add-cohort-overview__overview-title default-text">Duration</p>
              <p class="add-cohort-overview__overview-data default-text">4 Days</p>
           </div>
           <div class="add-cohort-overview__overview-item flex-column">
              <p class="add-cohort-overview__overview-title default-text">Cohort size</p>
              <p class="add-cohort-overview__overview-data default-text">10</p>
           </div>
        </div>
        <div class="add-cohort-overview__actions flex-column">
          <TermsAgreement tag="I have read and agree with the" spanTag="Terms of Service & Privacy Policy"/>
          <el-button type="primary" class="add-cohort-overview__button" :class="{'isActive': agreedToTerms}" round :loading="submitting" @click="goToCohorts">
                create cohort
            </el-button>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import TermsAgreement from '@/core/components/ui/TermsAgreement.vue';

export default defineComponent({
  components: {
    TermsAgreement
  },
  data() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
.add-cohort-overview {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
  border-radius: 30px;
  padding: 2rem;

  &__header {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;

    span {
        font-weight: 600;
        color: #9B9FB8;
    }
  }

  &__overview-item {
    gap: 0;
  }

  &__overview-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
  &__overview-data {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  &__button {
    width: 100%;
    background: rgba(12, 15, 74, 0.1);
    box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    letter-spacing: 1.25px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(12, 15, 74, 0.5);
  }
  &__button.active {
    background: #FAA200;
    color: #FFF;
  }
}
</style>
