<template>
    <div class="add-cohort-form grid">
        <div class="add-cohort-form__row flex-column">
            <div class="add-cohort-form__header-wrapper flex-row jc-sb ai-c">
                <p class="add-cohort-form__header-title default-text">Add new cohort</p>
            </div>
            <el-form :model="form" class="add-cohort-form__default-form" label-position="top">
                <el-form-item label="Select Course">
                <el-select v-model="form.course" class="options" placeholder="e.g. Air Source Heat Pump Level 3">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
                </el-form-item>
                <el-form-item label="Start date">
                    <el-date-picker class="expiry-date"
                    v-model="form.startDate"
                    type="date"
                    :format="DATE_FORMAT"
                    :value-format="'YYYY-MM-DD'"
                    :placeholder="DATE_FORMAT"
                    :disabledDate="disabledDate">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Start time">
                    <el-time-select
                        v-model="form.startTime"
                        start="00:00"
                        step="00:30"
                        end="23:59"
                        placeholder="e.g. 09:00 AM"
                        format="hh:mm A"
                    />
                </el-form-item>
                <el-form-item label="Duration">
                <el-input v-model="form.duration" placeholder="" autocomplete="off" />
                </el-form-item>
                <el-form-item label="Cohort size">
                <el-input v-model="form.cohortSize" placeholder="e.g. 10" autocomplete="off" />
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

import { DATE_FORMAT } from '@/core/constants';

export default {
  data() {
    return {
      DATE_FORMAT,
      form: {
        course: '',
        startDate: '',
        startTime: '',
        duration: '',
        cohortSize: '',
      },
      disabledDate(date) {
        return date < moment(new Date()).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).toDate();
      },
      options: [
        {
          label: 'Air Source Heat Pump Level 3',
          value: 1
        },
        {
          label: 'Solar PV Installation & Maintenance',
          value: 2
        }
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.add-cohort-form {
    grid-template-columns: 1fr 1fr;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
    border-radius: 30px;
    padding: 2rem;

    &__row {
        width: 100%;
        height: 100%;
    }

    &__header-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.15000000596046448px;
    }

    .el-form {
      .el-form-item {
        margin-bottom: 1rem;
        :deep(.el-select) {
            width: 100%;
        }
        :deep(.el-form-item__label) {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
        }
        :deep(.el-input__inner) {
            font-family: Mulish;
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
            background: rgba(12, 15, 74, 0.05);
            border-radius: 100px;
            border:none;
            outline: none;
        }
        :deep(.el-date-editor) {
            border:none;
            width: 100%;
        }
      }
    }
}
</style>
